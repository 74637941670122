<template>
  <section class="pd-20">
    <aPageHeader style="padding: 0" sub-title="">
      <template #title>
        <h1><aIcon class="gradient" type="bank" /> Contas Bancárias</h1>
      </template>
      <template #extra>
        <a-button type="primary" ghost @click="openCreateBankAccount = true">
          <aIcon type="bank" /> NOVA CONTA BANCÁRIA
        </a-button>
      </template>
    </aPageHeader>

    <aTabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <aTabPane key="Ativo" tab="ATIVO" force-render> </aTabPane>
      <aTabPane key="Desativado" tab="DESATIVADO" force-render> </aTabPane>
    </aTabs>

    <aCollapse
      class="travel-filters expandable mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <aIcon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="mt-0 mb-0" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID</label>
              <a-input
                placeholder="Digite"
                v-model="companyBankAccounts.filters.id"
                @change="getCompanyBankAccounts()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="columns"
      :data-source="companyBankAccounts.list"
      :loading="companyBankAccounts.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="companyBankAccountsTableChange"
    >
      <div slot="id" slot-scope="text">{{ text }}</div>

      <div slot="company_name" slot-scope="record">
        {{ record }}
      </div>

      <div slot="bank_id" slot-scope="record">
        {{ banks[record - 1].nome }}
      </div>

      <div slot="logo" slot-scope="record">
        <aAvatar v-if="record" :src="record" :size="30" />
      </div>

      <span class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a class="edit" @click="edit(record.id)">
            <aIcon type="edit-svg" />
          </a>
        </a-tooltip>
        <a-popconfirm
          v-if="$root.isAdmin()"
          title="Tem certeza que deseja apagar?"
          ok-text="Sim"
          placement="left"
          cancel-text="Não"
          @confirm="confirmDelete(record.id)"
        >
          <aIcon slot="icon" type="question-circle-o" style="color: red" />

          <a class="ml-15 delete">
            <aIcon type="delete" />
          </a>
        </a-popconfirm>
      </span>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a class="edit ml-15" @click="edit(record.id)">
            <aIcon slot="prefix" :type="'eye-svg'" />
          </a>
        </div>
      </template>
    </aTable>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="companyBankAccounts.pagination.page"
        :total="companyBankAccounts.pagination.total"
        @change="changeCompanyBankAccountsPage"
        @showSizeChange="changecompanyBankAccountsPageSize"
      />
    </div>

    <aModal
      class="miles-card-modal"
      v-model="viewBankAccount"
      width="1000px"
      :footer="false"
      @close="selectedBankAccountId = 0"
    >
      <CompanyBankAccountsModal
        :bankAccountId="selectedBankAccountId"
        v-if="viewBankAccount"
        @listcompanyBankAccountsAfterCreate="listcompanyBankAccountsAfterCreate"
      />
    </aModal>

    <aDrawer
      title="NOVA CONTA BANCÁRIA"
      placement="right"
      width="640px"
      class="travel-drawer"
      :closable="true"
      :visible="openCreateBankAccount"
      @close="openCreateBankAccount = false"
    >
      <EditCompanyBankAccounts
        v-if="openCreateBankAccount"
        type="create"
        :fieldSizes="{
          bank_id: 12,
          company_name: 12,
          company_id: 12,
          cnpj: 12,
          agency: 5,
          account: 6,
          operation: 5,
          status: 8,
          logo: 24,
          allow_payments: 24,
          bank_manager_name: 12,
          bank_manager_last_name: 12,
          bank_manager_main_phone: 12,
          bank_manager_phone: 12,
          bank_manager_email: 12,
        }"
        @listcompanyBankAccountsAfterCreate="listcompanyBankAccountsAfterCreate"
      />
    </aDrawer>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import banks from "@/json/banks.json";
import companyBankAccountsMixins from "@/mixins/company-bank-accounts/companyBankAccountsMixins.js";
import EditCompanyBankAccounts from "@/components/company-bank-accounts/forms/EditCompanyBankAccounts.vue";
import CompanyBankAccountsModal from "@/components/company-bank-accounts/modal/CompanyBankAccountsModal.vue";

export default {
  name: "ListBankAccounts",
  components: { EditCompanyBankAccounts, CompanyBankAccountsModal },
  mixins: [formatThings, companyBankAccountsMixins],
  data() {
    return {
      activeTab: "Ativo",
      openCreateBankAccount: false,
      viewBankAccount: false,
      selectedBankAccountId: 0,
      banks,
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 50,
        },
        {
          title: "",
          dataIndex: "logo",
          scopedSlots: { customRender: "logo" },
          width: 50,
        },
        {
          title: "Nome da Conta",
          dataIndex: "company_name",
          key: "company_name",
          scopedSlots: { customRender: "company_name" },
        },
        {
          title: "CNPJ",
          dataIndex: "cnpj",
          key: "cnpj",
          scopedSlots: { customRender: "cnpj" },
        },

        {
          title: "N° Banco",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "Banco",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Agência",
          dataIndex: "agency",
          key: "agency",
          scopedSlots: { customRender: "agencyc" },
        },
        {
          title: "Conta",
          dataIndex: "account",
          key: "account",
          scopedSlots: { customRender: "account" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    document.title = "Contas Bancárias - HAYA";
    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.companyBankAccounts.filters.users.list = data.data;
    });
    this.companyBankAccounts.filters.status.selected = this.activeTab;
    this.getCompanyBankAccounts();
  },
  methods: {
    listcompanyBankAccountsAfterCreate() {
      this.openCreateBankAccount = false;
      this.getCompanyBankAccounts();
    },
    getCustomers(name) {
      this.$http
        .get(`/customer/list?page=1&per_page=10&s=${name}`)
        .then(({ data }) => {
          this.companyBankAccounts.filters.customers.list = data.data;
        });
    },
    searchCustomers(term) {
      this.getCustomers(term);
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.companyBankAccounts.filters.status.selected = this.activeTab;
      this.getCompanyBankAccounts();
    },
    listVouchers() {
      this.openCreateBankAccount = false;
      this.getCompanyBankAccounts();
    },
    edit(id) {
      this.viewBankAccount = true;
      this.selectedBankAccountId = id;
    },
    changePage(current) {
      this.companyBankAccounts.pagination.page = current;
      this.getCompanyBankAccounts();
    },
    changePageSize(current, pageSize) {
      this.companyBankAccounts.pagination.page = current;
      this.companyBankAccounts.pagination.perPage = pageSize;
      this.getCompanyBankAccounts();
    },
  },
};
</script>
